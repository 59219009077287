<template>
  <div class="NodeAuthorizationDetail">
     <head-block :activeIdx='activeManue'></head-block>
     <div class="container"> 
        <div class="back"> 
            <a-icon type="left"  @click='openBackConfirm'/>
            <span class="ml8" @click='openBackConfirm'>返回列表</span>
        </div> 
        <div class="commonWhiteBg newsDetail"> 
            <h3 class="bigTittle"> 
              {{rowData.tittle}}
            </h3>
            <div class="time"> 
              {{rowData.time}}
            </div>
            <div class="desc">
            </div>
            <div class="img">
                 <el-image :src="rowData.img"
                 :preview-src-list="[rowData.img]" alt="">
                 </el-image>
            </div>
        </div>

     </div>
   
    <foot-block></foot-block>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeadBlock from '@/components/common/HeadBlock'
import FootBlock from '@/components/common/FootBlock'
export default {
  name: 'NodeAuthorizationDetail',
  components: {
    FootBlock,
    HeadBlock,
  },
  props: {
    // 配件需求确认行数据
    rowData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
     
    }
  },
      computed: {
    ...mapGetters(['activeManue'])
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    
    },
   // 返回列表
     openBackConfirm() {
      this.$emit('dialog-close')
    },
  }
}
</script>

<style lang="scss" scoped>
.NodeAuthorizationDetail{
  padding-top: 84px;
}
.back{
  margin:16px 0;
  display: flex;
  align-items: center;
  &>*{
    cursor: pointer;
  }
}
.newsDetail{
  text-align: center;
  padding: 28px 4%;
   .bigTittle{
     font-size: 24px;
   }
   .desc{
     font-size: 16px;
     text-align: left;
     text-indent: 2em;
     line-height: 36px;
   }
   .time{
      color: rgba(0, 18, 22, 0.65);
      margin:8px 0 24px;
   }
   .img{
     max-width: 90%;
     margin: 24px auto;
    //  max-height: 250px;
     .el-image{width: 60%;display: block;margin: 20px auto;}
   }
}
</style>
