<template>
  <div class="news">
    <div class="container">
      <h3 class="bigTittle">
        节点授权
      </h3>
      <ul class="newsList">
        <li
          v-for="(item,index) in newsList2"
          :key="index"
          @click="onEditOrder(item)"
        >
          <div class="contentCol">
            <h4 class="tittle">{{item.tittle}}</h4>
            <div class="desc">{{item.desc}}</div>
            <div class="time">{{item.time}}</div>
          </div>
          <div class="imgCol">
            <img :src="item.img">
          </div>
        </li>
      </ul>
      <!-- <div class="seeMore">
        <span>查看全部</span>
        <a-icon type="right" />
      </div> -->
    </div>
    <div class="container">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>


      <!--新增入库单抽屉-->
    <transition name="slide">
      <NodeAuthorization-Detail
        v-if="isInputDrawerShow"
        :rowData="currentRepairOrderRow"
        class="slide-dialog"
        @dialog-close="onInputDrawerClose"
      />
    </transition>

  </div>
</template>

<script>
import NodeAuthorizationDetail from './NodeAuthorizationDetail'
export default {
  name: 'newsList',
 components: {
    NodeAuthorizationDetail
  },
  data () {
    return {
        // 录入/补录 抽屉显示
      isInputDrawerShow: false,
      // 外修单ID
      currentRepairOrderRow: {},
      newsList: [
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization001.jpg',
          tittle: '学术论文区块链节点授权证书（一）',
          desc: '兹授予 中科康源(湖北)管理有限公司 为中国知链超级节点机构',
          time: '2024-07-01'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization01.jpg',
          tittle: '学术论文区块链节点授权证书（二）',
          desc: '兹授予 广东万方数据信息科技有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization02.jpg',
          tittle: '学术论文区块链节点授权证书（三）',
          desc: '兹授予 中国数字图书馆有限责任公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization03.jpg',
          tittle: '学术论文区块链节点授权证书（四）',
          desc: '兹授予 中国出版集团东方出版中心 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization04.jpg',
          tittle: '学术论文区块链节点授权证书（五）',
          desc: '兹授予 长江日报 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization05.jpg',
          tittle: '学术论文区块链节点授权证书（六）',
          desc: '兹授予 《长江文库》 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization06.jpg',
          tittle: '学术论文区块链节点授权证书（七）',
          desc: '兹授予 《电脑校园》 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization07.jpg',
          tittle: '学术论文区块链节点授权证书（八）',
          desc: '兹授予 《商情》 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization08.jpg',
          tittle: '学术论文区块链节点授权证书（九）',
          desc: '兹授予 《中国教育信息化》杂志社 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization09.jpg',
          tittle: '学术论文区块链节点授权证书（十）',
          desc: '兹授予 武汉天卷传媒有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization10.jpg',
          tittle: '学术论文区块链节点授权证书（十一）',
          desc: '兹授予 北京中品信联管理咨询有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization11.jpg',
          tittle: '学术论文区块链节点授权证书（十二）',
          desc: '兹授予 国商信公共服务平台技术（北京）有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization12.jpg',
          tittle: '学术论文区块链节点授权证书（十三）',
          desc: '兹授予 深圳市中品信用技术有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization13.jpg',
          tittle: '学术论文区块链节点授权证书（十四）',
          desc: '兹授予 大百科开源出版（武汉）有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization14.jpg',
          tittle: '学术论文区块链节点授权证书（十五）',
          desc: '兹授予 武汉蝌蚪信息技术有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization15.jpg',
          tittle: '学术论文区块链节点授权证书（十六）',
          desc: '兹授予 广州时波网络技术有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/NodeAuthorization16.jpg',
          tittle: '学术论文区块链节点授权证书（十七）',
          desc: '兹授予 广东宏大认证咨询有限公司 为中国知链学术论文节点机构',
          time: '2024-01-05'
        },
      ],
      newsList2: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total:0
      },
    }
  },

  mounted () {
    // this.init()
    this.pagination.total = this.newsList.length*1
    this.initPageLists()
  },
  methods: {
    init () {
      this.$store.commit('setActiveManue', 6)

    },
    // 录入/补录 抽屉打开
    onEditOrder(data) {
      console.log("onEditOrder...")
      this.currentRepairOrderRow = data
      this.isInputDrawerShow = true
    },
     // 录入/补录 抽屉关闭
    onInputDrawerClose() {
      this.isInputDrawerShow = false
    },

    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.newsList2 = this.newsList.slice((pageNum-1)*10,pageNum*10)
    },
    initPageLists(){
        this.newsList2 = this.newsList.slice(0,10)
    }
  },
}
</script>

<style lang='scss' scoped>
.news {
  min-height: 650px;
  padding-bottom: 50px;
  .container:first-child{
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
    margin: 16px auto;
    padding: 28px 0 0;
    color: rgba(0, 18, 22, 0.85);
    .bigTittle {
      padding: 4px 4%;
      font-size: 24px;
    }
  }
}
.newsList {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 4%;
    cursor: pointer;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    .imgCol {
      width: 10%;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .contentCol {
      width: 70%;
      .tittle {
        font-size: 20px;
      }
      .desc {
        color: rgba(0, 18, 22, 0.65);
        margin: 16px 0 14px;
      }
      .time{
          color: rgba(0, 18, 22, 0.65); 
      }
    }
  }
  &>li:last-child{
      border-bottom: none;
  }
}
.seeMore {
  background: #fafafa;
  padding: 17px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 18, 22, 0.65);
  cursor: pointer;
  .anticon {
    margin-left: 4px;
    font-size: 12px;
  }
}
.el-pagination{
    text-align: right;
    margin-top:16px;
}
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .newsList {
    li {
        flex-wrap: wrap;
      .imgCol {
        order: 1;
        width: 100%;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 16px;
      }
      .contentCol {
        order: 2;
        width: 100%;
      }
    }
  }
}
</style>
